import React, {Component} from "react";
export class Footer extends Component{
    static displayName = Footer.name;
    render(){
        return(
            <footer>
                <ul className="social-icons">
                    <li><a href="https://www.facebook.com/profile.php?id=61554415762953" target='_blank' rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://www.instagram.com/studio.proteus/" target='_blank' rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                </ul>
                <ul className="menu">
                    <li><a href="#home">Úvod</a></li>
                    <li><a href="#about">O nás</a></li>
                    <li><a href="#services">Služby</a></li>
                    <li><a href="#contact">Kontakt</a></li>
                </ul>
                <p>@2023 STUDIO PROTEUS | All Rights Reserved</p>
            </footer>
        );
    }
}