import React, {Component} from "react";
export class Service extends Component{
    static displayName = Service.name;
    render(){
        return(
            <div className="services" id="services">
                <div class="heading">
                    <h1>Naše služby</h1>
                </div>
                <div className="container reveal">
                    <div className="box">
                        <div className="content">
                            <div className="icon"><ion-icon name="brush-outline"></ion-icon></div>
                            <div className="text">
                                <h3>Designing</h3>
                                <div className="text-p">
                                <p>Naše grafické práce jsou přizpůsobené pro různé účely, jako jsou loga, vizitky, letáky, bannerové reklamy a další. Spolupracujeme s klienty na vytvoření vizuálního stylu, který bude přesně vyjadřovat osobnost jejich podnikání. Tým našich zkušených grafických designérů se vždy snaží předčit očekávání a doručit kreativní a profesionální výsledky.</p>
                                </div>
                                <a href="#contact">Kontaktuj nás</a>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="content">
                            <div className="icon"><ion-icon name="code-slash-outline"></ion-icon></div>
                            <div className="text">
                                <h3>Websites</h3>
                                <div className="text-p">

                                
                                <p>S využitím moderního designu a nejnovějších technologií vytváříme webové stránky, které nejen atraktivně prezentují obsah, ale také poskytují optimální uživatelský zážitek. Náš tým  designérů a vývojářů je připraven vytvořit unikátní online prostor, který skvěle reprezentuje vaši firmu či projekt. Webové stránky budou účinným nástrojem pro dosažení vašich cílů. </p>
                                </div>
                                <a href="#contact">Kontaktuj nás</a>
                            </div>
                        </div>
                    </div>
                    <div className="box">
                        <div className="content">
                            <div className="icon"><ion-icon name="camera-outline"></ion-icon></div>
                            <div className="text">
                                <h3>Multimedia</h3>
                                <div className="text-p">
                                    <p>Fotografie a tvorba videí představuje další z významných prvků naší studia. Poskytujeme rozsáhlé služby, včetně fotografování produktů, firemních portrétů, reklamních fotografií a videí, stejně jako postprodukční úpravy a editace. Spolupracujeme s našimi klienty na vytváření jedinečného obsahu, který zaujme a osloví jejich cílovou skupinu.</p>
                                </div>
                                <a href="#contact">Kontaktuj nás</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}