import React, {Component} from "react";
import emailjs from 'emailjs-com';

export class Contact extends Component{
    static displayName = Contact.name;
    render(){
        function sendEmail(e){
            e.preventDefault();
            emailjs.sendForm('service_nc1606s', 'ProteusSiteTemplate', e.target, 'fFGOQM6JwyYj3dJeI')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            e.target.reset()
        }
        return(
            <div>
                <div class="heading">
                    <h1>Kontaktuj  nás!</h1>
                </div>
                <section class="contact" id="contact"> 
                    <div class="container">
                        <div class="content">
                            <div class="left-side">
                                <div class="adress details">
                                    <i class="fas fa-map-marker-alt"></i>
                                    <div class="topic">Adresa</div>
                                    <div class="text-one">Liberec, Česká republika</div>
                                    <div class="text-two">Mimoňská 21, 460 01</div>
                                    <div class="text-two">IČ: 09672702</div>
                                </div>
                                <div class="phone  details">
                                    <i class="fas fa-phone-alt"></i>
                                    <div class="topic">Telefon</div>
                                    <div class="text-one">+420 725 552 200</div>
                                </div>
                                <div class="email details">
                                    <i class="fas fa-envelope"></i>
                                    <div class="topic">Email</div>
                                    <div class="text-one">info@studio-proteus.cz</div>
                                </div>
                            </div>
                            <div class="right-side">
                                <div class="topic-text">Napište nám!</div>
                                <p>Pokud vás naše nabídka služeb zaujala, tak nás neváhejte kontaktovat!</p>
                            
                                <form onSubmit={sendEmail}>
                                    <div class="input-box">
                                        <input type="text" name="fullname" id="fullname" placeholder="Napište své jméno" required/>
                                    </div>
                                    <div class="input-box">
                                        <input type="email" name="email" id="email" placeholder="Napište svůj email" required/>
                                    </div>
                                    <div class="input-box">
                                        <input type="text" name="telefon" id="telefon" placeholder="Napište svůj telefon" required/>
                                    </div>
                                    <div class="input-box">
                                        <input type="text" name="message" id="message"  placeholder="Napište, o kterou službu bys měl zájem" required/>
                                    </div>
                                    <div class="btn">
                                        <input type="submit" name="" class="action_btn" value="Odeslat"/>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
            
        );
    }
}